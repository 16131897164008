const colors = {
  brand: {
    900: '#1a365d',
    800: '#153e75',
    700: '#2a69ac',
  },
  header: {
    100: '#20232a',
  },
  main: {
    100: '#282c34',
  },
};

export default colors;
