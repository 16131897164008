import React, { useEffect, useRef, useState } from 'react';
import {
  Center,
  Flex,
  Heading,
  Box,
  Text,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  InputGroup,
  InputRightElement,
  Button,
  useClipboard,
  ButtonGroup,
  Select,
  useToast,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  SimpleGrid,
  theme,
} from '@chakra-ui/react';
import styled from 'styled-components';

import SudokuToolCollection from 'sudokutoolcollection';
let sudoku = SudokuToolCollection();

const Main: React.FC = () => {
  /* -------------------------------------------------------------------------- */
  /*                               Initial puzzle                               */
  /* -------------------------------------------------------------------------- */

  const emptyPuzzle = [
    ['.', '.', '.', '.', '.', '.', '.', '.', '.'],
    ['.', '.', '.', '.', '.', '.', '.', '.', '.'],
    ['.', '.', '.', '.', '.', '.', '.', '.', '.'],
    ['.', '.', '.', '.', '.', '.', '.', '.', '.'],
    ['.', '.', '.', '.', '.', '.', '.', '.', '.'],
    ['.', '.', '.', '.', '.', '.', '.', '.', '.'],
    ['.', '.', '.', '.', '.', '.', '.', '.', '.'],
    ['.', '.', '.', '.', '.', '.', '.', '.', '.'],
    ['.', '.', '.', '.', '.', '.', '.', '.', '.'],
  ];

  /* -------------------------------------------------------------------------- */
  /*                              State management                              */
  /* -------------------------------------------------------------------------- */

  const [puzzle, setPuzzle] = useState(emptyPuzzle);
  const [solvedPuzzle, setSolvedPuzzle] = useState(emptyPuzzle);
  const [initialPuzzle, setInitialPuzzle] = useState(emptyPuzzle);
  const [puzzleDifficulty, setPuzzleDifficulty] = useState('medium');
  const [sudokuString, setSudokuString] = useState('');
  const [hasSudokuString, setHasSudokuString] = useState(false);

  /* --------------------------- Chakra dependancies -------------------------- */
  const toast = useToast();
  const { hasCopied, onCopy } = useClipboard(sudokuString);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const cancelRef = useRef();
  // const gridChildren = useRef([]);

  /* -------------------------------------------------------------------------- */
  /*                            New puzzle generation                           */
  /* -------------------------------------------------------------------------- */

  const generateNewPuzzle = () => {
    console.log(
      '🧮 GENERATING NEW PUZZLE WITH DIFFICULTY: ' + puzzleDifficulty
    );

    // Generate puzzle based on selected difficulty and update state.
    const newPuzzle = sudoku.generator.generate(puzzleDifficulty);
    setPuzzle(sudoku.conversions.stringToGrid(newPuzzle));
    setInitialPuzzle(sudoku.conversions.stringToGrid(newPuzzle));

    // Convert sudoku string based off of the new puzzle and update state.
    const newSudokuString = newPuzzle.replace(/\./g, '0');
    setSudokuString(newSudokuString);
    setHasSudokuString(true);

    // Solve the generated puzzle and update state.
    const newPuzzleSolution = sudoku.solver.solve(newPuzzle);
    const newPuzzleSolutionGrid =
      sudoku.conversions.stringToGrid(newPuzzleSolution);
    setSolvedPuzzle(newPuzzleSolutionGrid);

    // ! Show solved puzzle in console for debug purposes!
    // TODO Remove the solved puzzle in production build so solution can not be found easily.
    console.log(
      '🚀 ~ file: index.tsx ~ line 89 ~ generateNewPuzzle ~ newPuzzleSolutionGrid',
      newPuzzleSolutionGrid
    );
  };

  /* -------------------------------------------------------------------------- */
  /*                           Check sudoku for errors                          */
  /* -------------------------------------------------------------------------- */

  const sudokuChecker = () => {
    puzzle.map((row, rowIndex) => {
      row.map((cell, cellIndex) => {
        /**
         * Check to see if a cell is not empty,
         * undefined or null.
         */
        if (cell) {
          /**
           * Check to see if cell is not part
           * of the initial given numbers.
           */
          if (initialPuzzle[rowIndex][cellIndex] !== cell) {
            /**
             * Check to see if the cell matches
             * the solution's cell at the same
             * location.
             */

            // TODO Write solving logics.

            if (solvedPuzzle[rowIndex][cellIndex] === cell) {
              // * Cell is correct
              console.log(`Cell at [${rowIndex},${cellIndex}] is correct.`);
              return true;
            } else {
              // ! Cell is incorrect
              return false;
              // TODO Error handling system where a user can only make X mistakes.
            }
          }
        }
      });
    });
  };

  /* -------------------------------------------------------------------------- */
  /*                     Handle changes in the puzzle fields                    */
  /* -------------------------------------------------------------------------- */

  const sudokuChange = (value, cellRowIndex, cellIndex) => {
    let newPuzzle = puzzle;
    newPuzzle[cellRowIndex][cellIndex] = value;
    setPuzzle((newPuzzle) => [...newPuzzle]);
  };

  /* -------------------------------------------------------------------------- */
  /*                                 Initial run                                */
  /* -------------------------------------------------------------------------- */

  /**
   * Runs code that should happen when the page is
   * loaded and initiated.
   */
  useEffect(() => {
    console.log('🚀 INITIAL RUN');

    generateNewPuzzle();
  }, []);

  /**
   * Runs every time the sudoku puzzle is updated;
   * This can happen because of:
   *  - The generation of a new puzzle.
   *  - The resetting of the puzzle.
   *  - Changing of an input within the grid.
   */
  useEffect(() => {
    sudokuChecker();
  }, [puzzle]);

  /* -------------------------------------------------------------------------- */
  /*                            Handle input changes                            */
  /* -------------------------------------------------------------------------- */

  /**
   * Handles what should happen if the Sudoku code is manually changed.
   * @param event Event containing the string as a target value.
   */
  const handleSudokuStringChange = (event) => {
    const value = event.target.value;
    setHasSudokuString(true);
    setSudokuString(value);
  };

  /**
   * Handles the selection of difficulties using a select input.
   * @param event Event containing the selected value of the select input.
   */
  const handleSudokuDifficultyChange = (event) => {
    setPuzzleDifficulty(event.target.value);
  };

  /**
   * Handles the click of the "Generate new" button.
   */
  const handleGenerateButtonClick = () => {
    generateNewPuzzle();

    toast({
      title: 'A new puzzle is ready.',
      description: 'Your new puzzle was created and is ready to be filled in!',
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
  };

  /**
   * Handles input changes of a cell in the grid.
   * @param event Event containing all info pertaining the input change.
   * @param cellRowIndex Row of the changed cell
   * @param cellIndex Column of the changed cell
   */
  const handleSudokuCellChange = (event, cellRowIndex, cellIndex) => {
    const newValue = event.target.value;

    // TODO Check for values longer than 1 (so check that user can not enter 10, 100, ...)

    // Check if the entered value is a number
    if (!/^\d+$/.test(newValue) && newValue !== '') {
      toast({
        title: 'Wrong input!',
        description: 'You can only enter numbers.',
        duration: 3000,
        variant: 'subtle',
        status: 'error',
      });
    } else {
      sudokuChange(newValue, cellRowIndex, cellIndex);
    }
  };

  // TODO See if there's a way to make click & focus work seperately.
  /**
   * Handles the clicks of a sudoku cell in the grid.
   * This can be used for getting hints on the selected cell.
   * @param event click event
   * @param cellRowIndex Row of the clicked cell
   * @param cellIndex Column of the clicked cell
   */
  // const handleSudokuCellClick = (event, cellRowIndex, cellIndex) => {
  //   event.preventDefault();
  //   setSelectedCell([cellRowIndex, cellIndex]);
  //   // gridChildren[5].focus();
  //   // console.log(gridChildren.current[0]);
  //   gridChildren.current[0].focus();
  // };

  /* -------------------------------------------------------------------------- */
  /*                              Styled components                             */
  /* -------------------------------------------------------------------------- */

  const SudokuCellWrapper = styled.div`
    width: 100%;
    padding-top: 100%;
    position: relative;
    border-bottom: ${(props) =>
      props.cellRow === 2 || props.cellRow === 5
        ? `3px solid ${theme.colors.gray[600]}`
        : false};
    border-right: ${(props) =>
      props.cellColumn === 2 || props.cellColumn === 5
        ? `3px solid ${theme.colors.gray[600]}`
        : false};
  `;

  const SudokuCell = styled.input`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;

    font-weight: bold;
    font-size: 2rem;
    text-align: center;
    caret-color: transparent;

    border-top: ${(props) =>
      props.cellRow !== 0 ? `1px solid ${theme.colors.gray[200]}` : false};
    border-left: ${(props) =>
      props.cellColumn !== 0 ? `1px solid ${theme.colors.gray[200]}` : false};

    background-color: ${(props) =>
      props.disabled ? `${theme.colors.gray[50]}` : 'white'};
    color: ${(props) =>
      props.disabled
        ? `${theme.colors.gray[500]}`
        : props.isError
        ? `${theme.colors.red[500]}`
        : `${theme.colors.gray[700]}`};

    &:focus {
      outline: none;
      border: ${`2px solid ${theme.colors.blue[500]}`};
      background-color: ${`${theme.colors.blue[50]}`};
    }
  `;

  /* -------------------------------------------------------------------------- */
  /*                               Main app return                              */
  /* -------------------------------------------------------------------------- */

  return (
    <Flex minH='100vh' bg='gray.100'>
      <Box
        w='33.33%'
        bg='white'
        borderRadius='3xl'
        mt={{ base: 4, xl: 8 }}
        ml={{ base: 4, xl: 8 }}
        mb={{ base: 4, xl: 8 }}
        p={{ base: 6, xl: 12 }}
        boxShadow='2xl'
      >
        <Flex direction='column' justifyContent='space-between' h='100%'>
          <Flex direction='column'>
            <Heading mb='4'>Sudoku</Heading>
            <Text color='gray.500' mb='12'>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit.
              Exercitationem perspiciatis aut, corporis deserunt ducimus, nobis
              fugiat optio consectetur eius iusto quia, ipsam delectus magni!
              Repellendus labore itaque deserunt debitis facilis!
            </Text>

            <FormControl id='sudokucode'>
              <FormLabel>Sudoku string</FormLabel>

              <InputGroup size='md'>
                <Input
                  pr='6rem'
                  type='text'
                  placeholder='Enter a sudoku string'
                  onChange={(e) => {
                    handleSudokuStringChange(e);
                  }}
                  value={sudokuString}
                  isReadOnly
                />
                <InputRightElement width='5rem'>
                  <Button
                    size='sm'
                    w='100%'
                    mr='1'
                    disabled={!hasSudokuString || hasCopied}
                    onClick={onCopy}
                  >
                    {hasCopied ? 'Copied' : 'Copy'}
                  </Button>
                </InputRightElement>
              </InputGroup>

              <FormHelperText>
                A sudoku string is a long string containing the code of the
                Sudoku board.
              </FormHelperText>
            </FormControl>

            <FormControl id='difficulty' mt='8'>
              <FormLabel>Difficulty</FormLabel>

              <Select
                onChange={(e) => {
                  handleSudokuDifficultyChange(e);
                }}
                defaultValue='medium'
              >
                <option value='easy'>Easy</option>
                <option value='medium'>Medium</option>
                <option value='hard'>Hard</option>
                <option value='very-hard'>Very hard</option>
                <option value='insane'>Insane</option>
                <option value='inhuman'>Inhuman</option>
              </Select>

              <FormHelperText>
                A sudoku string is a long string containing the code of the
                Sudoku board.
              </FormHelperText>
            </FormControl>
          </Flex>

          <ButtonGroup spacing='2' size='md'>
            <Button
              colorScheme='blue'
              onClick={() => {
                setIsAlertOpen(true);
              }}
            >
              Generate new puzzle
            </Button>

            <AlertDialog
              isOpen={isAlertOpen}
              leastDestructiveRef={cancelRef}
              onClose={() => {
                setIsAlertOpen(false);
              }}
              isCentered
            >
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                    Generate new puzzle
                  </AlertDialogHeader>

                  <AlertDialogBody>
                    Are you sure you want to generate a new puzzle? You can't
                    undo this action afterwards.
                  </AlertDialogBody>

                  <AlertDialogFooter>
                    <Button
                      ref={cancelRef}
                      onClick={() => {
                        setIsAlertOpen(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      colorScheme='red'
                      onClick={() => {
                        setIsAlertOpen(false);
                        handleGenerateButtonClick();
                      }}
                      ml={3}
                    >
                      Generate
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>

            <Button
              colorScheme='red'
              variant='ghost'
              onClick={() => {
                alert('reset puzzle');
              }}
            >
              Reset
            </Button>
          </ButtonGroup>
        </Flex>
      </Box>

      <Center w='66.66%'>
        <SimpleGrid
          columns={9}
          w='xl'
          bg='white'
          p='8'
          borderRadius='xl'
          boxShadow='xl'
          sx={{ position: 'relative' }}
        >
          {puzzle.map((cellRow, cellRowIndex) => {
            const cellContent = cellRow.map((cell, cellIndex) => {
              /* ----------- Check to see if a cell is an initially filled cell ----------- */
              let isOriginalCell = false;

              if (
                initialPuzzle[cellRowIndex][cellIndex] === cell &&
                cell !== '.'
              ) {
                isOriginalCell = true;
              }

              /* ---------------------------- Return all cells ---------------------------- */
              return (
                <SudokuCellWrapper
                  cellRow={cellRowIndex}
                  cellColumn={cellIndex}
                  key={'row ' + cellRowIndex + cellIndex}
                >
                  <SudokuCell
                    value={cell === '.' ? '' : cell}
                    cellRow={cellRowIndex}
                    cellColumn={cellIndex}
                    disabled={isOriginalCell}
                    key={'cell ' + cellRowIndex + cellIndex}
                    onChange={(event) => {
                      handleSudokuCellChange(event, cellRowIndex, cellIndex);
                    }}
                    // ref={(element) => gridChildren.current.push(element)}
                  ></SudokuCell>
                </SudokuCellWrapper>
              );
            });

            return cellContent;
          })}
        </SimpleGrid>
      </Center>
    </Flex>
  );
};

export default Main;
